





























import { IRedLogisticCode, IRedPreferences } from '@/types/interfaces';
import { Component, Vue } from "vue-property-decorator";
import { ActionMethod } from 'vuex';
import { Action, State, Getter } from 'vuex-class';

@Component({})
export default class PreferencesPage extends Vue {
  public loading: Boolean = true

  @State(state => state.redPreferences.preferences) preferences: IRedPreferences;
  @State(state => state.redPreferences.codes) codes: IRedLogisticCode[];

  @Getter('context/getConsumerName') consumerName: string;

  @Action('fetchPreferences', { namespace: 'redPreferences' }) fetchPreferences: ActionMethod;
  @Action('updatePreferences', { namespace: 'redPreferences' }) updatePreferences: ActionMethod;
  @Action('fetchLogisticCodes', { namespace: 'redPreferences' }) fetchLogisticCodes: ActionMethod;

  async mounted() {
    await this.fetchPreferences()
    await this.fetchLogisticCodes()
    this.loading = false
  }

  submit() {

    var p1 = this.updatePreferences(this.preferences)

    Promise.all([p1]).then((result: any) => {
      this.$notify({ type: 'success', text: 'Preferences successfully updated' })
    }).catch(err => {
      this.$notify({ type: 'error', text: err.message })
    });
  }
}
